<template>
    <div class="pagePolicy">
        <div class="pageInfos">
            <div v-if="showNewTerms" class="confirmationMessage">
                {{ $t('register.form.termsAndConditions.newTerms') }}
            </div>
            <TermsAndConditionsPanel/>
            <div v-if="showConfirmation" class="actions">
                <v-checkbox class="mt-0"
                    v-model="isTermsAndConditionAccepted"
                    hide-details
                >
                    <template v-slot:label>
                        <div>{{$t('policies.termsAndConditions.readAndAcceptTerms')}}</div>
                    </template>
                </v-checkbox>
                <v-btn color="primary" @click="confirm" :disabled="!isTermsAndConditionAccepted">{{$t('policies.termsAndConditions.iAccept')}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons"
import terms from './terms.json'
import TermsAndConditionsPanel from './TermsAndConditionsPanel'
import termsAndConditionsService from '../../services/TermsAndConditionsService'
import storageService from '../../services/StorageService'
export default {
    data () {
        return {
            icons: {
                calendar: faCalendarAlt
            },
            terms: terms,
            isTermsAndConditionAccepted: false
        }
    },
    computed: {
        getLocalizedTerms() {
            return terms[this.$i18n.locale]
        },
        showNewTerms() {
            const atv = storageService.acceptedTermsVersion
            const newTerms = atv !== "0" && atv !== this.terms.version
            return newTerms && this.$route.query.v !== undefined
        },
        showConfirmation() {
            return storageService.acceptedTermsVersion !== this.terms.version
        }
    },
    components: {
        TermsAndConditionsPanel
    },
    methods: {
        confirm() {
            const termsAndConditionsAnswer = {
                version: parseInt(this.terms.version),
                areTermsAndContionsAccepted: this.isTermsAndConditionAccepted
            }
            termsAndConditionsService.handleUserAnswer({ termsAndConditionsAnswer }).then(() => {
                storageService.acceptedTermsVersion = this.terms.version
                this.$router.push({ name: 'Home' })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/policies.scss";
.actions {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px -2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
</style>