import storageService from "../../services/StorageService";

var preference = {
    state: () => ({
        currency: 'CAD'
    }),
    mutations: {
        setUserCurrency(state, { currency }) {
            state.currency = currency
        }
    },
    actions: {
        initPreferences({ commit }) {
            const currency = storageService.userCurrency || 'CAD'
            commit('setUserCurrency', { currency })
        },
        setUserCurrency(context, { currency }) {
            storageService.userCurrency = currency
            context.commit('setUserCurrency', { currency })
        }
    },
    getters: {
        userCurrency(state) {
            return state.currency
        },
        conversionCurrency(state) {
            return state.currency === 'CAD' ? 'USD' : 'CAD'
        },
        getDefaultConversion(state) {
            const conversionCurrency = state.currency === 'CAD' ? 'USD' : 'CAD'
            return `${conversionCurrency},${state.currency}`.toUpperCase()
        }
    }
}

export default preference;