<template>
    <div class="simplePage">
        <div class="widget">
            <div class="widgetBody">
                <slot></slot>
            </div>
            <div class="widgetFooter">
                <div class="wfLeft">
                    <slot name="widgetFooterLeftPart"></slot>
                </div>
                <div class="wfRight">
                    <LanguageSwitcher></LanguageSwitcher>
                </div>
            </div>
        </div>
        <div class="simplePageFooter">
            <PoliciesAndContactUs></PoliciesAndContactUs>
        </div>
    </div>
</template>

<script>
import LanguageSwitcher from '@/components/controls/LanguageSwitcher'
import PoliciesAndContactUs from '../components/controls/PoliciesAndContactUs.vue';
export default {
    data() {
        return {
            valid: true
        };
    },
    components: {
        LanguageSwitcher,
        PoliciesAndContactUs
    }
}
</script>

<style lang="scss" scoped>
.simplePage {
    height: 100%;
    padding-top: 100px;
    background-color: var(--lightBG);
    overflow: auto;
    .widget {
        max-width: 800px;
        margin: auto;
        display: flex;
        flex-direction: column;
        .widgetBody {
            display: flex;
            flex-direction: column;
            justify-content: center;
            background-color: #FFF;
            border-radius: 10px;
            padding: 40px 0;
        }
        .widgetFooter {
            display: flex;
            padding: 16px;
            justify-content: space-between;
            align-items: center;
            .wfLeft {
                display: flex;
                align-items: center;
                gap: 15px;
            }
        }
    }
    .simplePageFooter {
        max-width: 800px;
        padding: 16px 0;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (device-width: 768px) {
/* For general iPad layouts */
    .widget {
        max-width: 80%;
        .widgetBody {
            padding: 0;
        }
    }
}

@media screen and (max-width: 750px) {
    .simplePage {
        padding: 70px 10px 10px;
        .widget {
            .widgetBody {
                padding: 10px 0;
            }
            .widgetFooter {
                gap: 20px;
                flex-direction: column;
            }
        }
        .simplePageFooter {
            flex-direction: column;
            gap: 20px;
        }
    }
}
</style>
