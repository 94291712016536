import DateHelper from "../../infra/DateHelper";
class StockChartBuilder {
    constructor({ yAxisLabelFormatter, isMobile }) {
        this._series = [];
        this._options = {};
        this._yAxisLabelFormatter = yAxisLabelFormatter;
        this._isMobile = isMobile;
        this._originalData = [];
        this._xaxisCategories = [];
        this._tickAmount = 4;
    }

    static get CHART_STEPS() {
        return [[1.0, 0.005], [2.0, 0.01], [10.0, 0.1], [100.0, 0.25], [500.0, 0.50], [1000.0, 1.0], [10000.0, 10.0], [100000.0, 100.0], [1000000.0, 1000.0]];
    }

    _aggregateData(data, interval, locale) {
        const dates = [...new Set(data.map(d => d[0]))];
        switch (interval) {
            case 'day':
                return dates.map(d => DateHelper.toShortTime(d));
            case 'week':
                return dates.map(d => DateHelper.toShortWeekday({ stringDate: d, locale }));
            case 'month':
            case 'sixMonths':
                return dates.map(d => DateHelper.toShortDay({ stringDate: d, locale }));
            case 'year':
            case 'fiveYears':
                return dates.map(d => DateHelper.toShortDayWithYear({ stringDate: d, locale }));
            default:
                return dates.map(d => DateHelper.toSimpleDateTime(d));
        }
    }

    _getTickAmount(interval) {
        switch (interval) {
            case 'day': return this._isMobile ? 6 : 8;
            case 'week': return undefined;
            case 'month': return this._isMobile ? 4 : 6;
            case 'sixMonths': return this._isMobile ? 4 : 6;
            case 'year': return 4;
            case '5years': return 5;
            default: return 4;
        }
    }

    _numCategories(data) {
        return Math.min(5, data.length);
    }

    _getColorsFromChange(change) {
        const colors = {};
        switch (Math.sign(change)) {
            case -1:
                colors.stroke = ['#b30000'];
                colors.fill = ['#ff0000'];
                break;
            case 1:
                colors.stroke = ['#1d6320'];
                colors.fill = ['#39c640'];
                break;
            default:
                colors.stroke = ['#404040'];
                colors.fill = ['#808080'];
                break;
        }
        return colors;
    }

    _getYAxisMinOld(val, gap) {
        if (val < 1.0) {
            return val;
        }
        if (val < 2.0) {
            return val.toFixed(2) - 0.01;
        }
        if (val < 50.0) {
            var min = Math.floor(val / 0.25) * 0.25;
            min = (val - min) < 0.05 ? min - 0.25 : min;
            return min;
        }
        if (val < 1000.0) {
            return Math.floor(val);
        }
        if (val < 10000.0) {
            return Math.floor(val / 5) * 5;
        }
        if (val < 100000.0) {
            return Math.floor(val / 100) * 100;
        }
        else {
            return Math.floor(val / 1000) * 1000;
        }
    }

    _getYAxisMaxOld(val, gap) {
        if (val < 1.0) {
            return val;
        }
        if (val < 2.0) {
            return parseFloat(val.toFixed(2)) + 0.01;
        }
        if (val < 50.0) {
            var max = Math.ceil(val / 0.25) * 0.25;
            max = (max - val) < 0.05 ? max + 0.25 : max;
            return max;
        }
        if (val < 1000.0) {
            return Math.ceil(val);
        }
        if (val < 10000.0) {
            return Math.ceil(val / 5) * 5;
        }
        if (val < 100000.0) {
            return Math.ceil(val / 100) * 100;
        }
        else {
            return Math.ceil(val / 1000) * 1000;
        }
    }

    _getChartStep(val) {
        const step = StockChartBuilder.CHART_STEPS.find(s => val < s[0]);
        return step ? {
            inc: step[1],
            proximity: step[1] / 4
        } : { inc: 1, proximity: 0.25 }
    }

    _getYAxisMin(val) {
        const step = this._getChartStep(val);
        var min = Math.floor(val / step.inc) * step.inc;
        min = (val - min) <= step.proximity ? min - step.inc : min;
        return min;
    }

    _getYAxisMax(val) {
        const step = this._getChartStep(val);
        var max = Math.ceil(val / step.inc) * step.inc;
        max = (max - val) <= step.proximity ? max + step.inc : max;
        return max;
    }

    _setYAxisMinMax({ data, previousClose }) {
        var prices = data.filter(val => val);
        previousClose && prices.push(previousClose);
        prices.sort((a, b) => {
            return a - b;
        });
        const minPrice = prices[0];
        const maxPrice = prices[prices.length - 1];
        const gap = maxPrice - minPrice;
        this._options.yaxis.min = this._getYAxisMin(minPrice, gap);
        this._options.yaxis.max = this._getYAxisMax(maxPrice, gap);
    }

    _init({ title, hourScale }) {
        this._series = [];

        this._options = {
            chart: {
                height: this._isMobile ? 175 : 250,
                type: 'area',
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            annotations: {
                yaxis: []
            },
            stroke: {
                width: 2
            },
            title: {
                text: title,
                style: {
                    fontWeight: '500',
                    fontFamily: '"Roboto", sans-serif',
                    fontSize: this._isMobile ? '10px' : '14px'
                }
            },
            fill: {},
            yaxis: {
                opposite: true,
                labels: {
                    formatter: this._yAxisLabelFormatter,
                    style: {
                        fontSize: this._isMobile ? '10px' : '12px'
                    }
                },
                tickAmount: this._isMobile ? 3 : 5
            },
            xaxis: {
                type: 'category',
                //categories: ['A', 'B', 'C', 'D', 'E'],
                //categories: ['9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00'],
                categories: this._xaxisCategories,
                labels: {
                    showDuplicates: false,
                    rotate: 0
                //     datetimeUTC: false,
                //     format: hourScale ? 'HH:mm' : undefined,
                //     style: {
                //         fontSize: this._isMobile ? '10px' : '12px'
                //     }//,
                //     // formatter: function (value) {
                //     //     // Vous pouvez personnaliser le format d'affichage des labels ici
                //     //     return new Date(value).toLocaleDateString('fr-CA');
                //     // }
                },
                tickAmount: this._tickAmount,
                tooltip: {
                    enabled: false
                }
            },
            tooltip: {
                //enabled: false
                x: {
                    //format: 'dd MMM HH:mm',
                    formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
                        return this._originalData[dataPointIndex][0]
                    }
                }
                // y: {
                //     formatter: this._yAxisLabelFormatter
                // },
                // fixed: {
                //     // enabled: false,
                //     // offsetX: 0,
                //     // offsetY: -20
                // }
            }
        };
    }

    buildChart({ title, name, data, activeInterval, previousClose, locale, hourScale = false }) {
        this._originalData = data.map(d => [DateHelper.toSimpleDateTime(d[0]), d[1]]);
        //this._originalData = data.map(d => [new Date(d[0]), d[1]]);
        // let startDate = new Date('2024-07-04T09:30:00');
        // Ajouter 39 valeurs avec des sauts de 10 minutes
        // for (let i = 0; i < 40; i++) {
        //     // Ajouter la date et une valeur (ici 0) à _originalData
        //     this._originalData.push([startDate, null]);
        //     // Ajouter 10 minutes à la date de début
        //     startDate = new Date(startDate.getTime() + 10 * 60000);
        // }
        // this._originalData.sort((a, b) => a[0] - b[0]);
// console.log('originalData', this._originalData);
        data = this._originalData.map(d => d[1]);
// console.log('data', data);

        //console.log('data', data);
        this._xaxisCategories = this._aggregateData(this._originalData, activeInterval, locale);
        this._tickAmount = this._getTickAmount(activeInterval);

        this._init({ title, hourScale });
        this._setChartColor({ data, previousClose });
        this._setPreviousCloseAnnotation(previousClose, activeInterval);
        // TODO : validation du data
        this._series.push({
            name: name,
            data
        });

        // établir l'échelle pour l'axe des Y (min/max)
        this._setYAxisMinMax({ data, previousClose });
        return {
            options: this._options,
            series: this._series
        }
    }

    get height() {
        return this._options.chart.height
    }

    set height(value) {
        this._options.chart.height = value;
    }

    _setPreviousCloseAnnotation(value, activeInterval) {
        if (activeInterval === 'day') {
            this._options.annotations.yaxis.push({
                y: value,
                strokeDashArray: 5,
                borderColor: '#777'
            });
        }
    }

    _setChartColor({ data, previousClose }) {
        const currentPrice = data[data.length - 1];
        const previousCloseRef = previousClose || data[0];
        const delta = currentPrice - previousCloseRef;
        const colors = this._getColorsFromChange(delta);
        this._options.stroke.colors = colors.stroke;
        this._options.fill.colors = colors.fill;
        this._options.chart.background = '#FFF';
    }
}

export default StockChartBuilder
