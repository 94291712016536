<template>
	<v-app>
		<v-app-bar app v-if="showAppBar" color="white">
            <div class="mainAppBar">
                <div class="CompName" @click="goHome">
                    <img src="~@/assets/Invagora_small_white.png" class="menuLogo"/>
                </div>
                <v-spacer></v-spacer>
                <AppBarInfos v-if="loggedIn"></AppBarInfos>
                <SubscriptionButtons></SubscriptionButtons>
                <HelpDialog v-if="false" ref="helpDialog"></HelpDialog>
                <Avatar v-if="loggedIn" @editSubscriptionAsked="editSubscriptionAsked" @helpAsked="helpAsked"></Avatar>
            </div>
		</v-app-bar>
		<v-main class="mainApp">
            <AppMessages v-if="loggedIn"></AppMessages>
			<BasicFeedback v-if="loggedIn && !$vuetify.breakpoint.mobile" @feedbackCallback="feedbackCallback"></BasicFeedback>
            <SySnackbar ref="iSySnackbar"></SySnackbar>
			<router-view></router-view>
            <CookiePolicyWidget v-if="!isCookiesAllowed"></CookiePolicyWidget>
		</v-main>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
	</v-app>
</template>

<script>
import Avatar from "./components/avatar/Avatar";
import HelpDialog from './components/help/HelpDialog'
import BasicFeedback from './components/feedback/BasicFeedback';
import { authComputed } from "./store/helpers/AuthenticationHelper";
import CookiePolicyWidget from './components/controls/CookiePolicyWidget.vue';
import storageService from "./services/StorageService";
import DateHelper from "./infra/DateHelper";
import InfosService from './services/InfosService'
import AppMessages from './components/controls/AppMessages.vue';
import SubscriptionButtons from './components/subscriptions/SubscriptionButtons.vue';
import SySnackbar from './components/controls/SySnackbar.vue';
import AppBarInfos from './components/home/dashboard/boards/AppBarInfos.vue';
export default {
    name: "App",
    title: 'Invagora',
    data: () => ({
        dialog: undefined,
        overlay: false
    }),
    components: {
        Avatar,
        BasicFeedback,
        HelpDialog,
        CookiePolicyWidget,
        AppMessages,
        SubscriptionButtons,
        SySnackbar,
        AppBarInfos
    },
    created() {
        this.$store.dispatch('setAllowCookies', { allowCookies: storageService.allowCookies })
        const infosService = new InfosService();
        infosService.getSystemDateTime().then(response => {
            console.log('System Date :', response)
            DateHelper.setSystemDate(response)
        })
        infosService.getPublicMainInfos().then(response => {
            this.$store.dispatch('setCurrencyExchangeRates', { currencyExchangeRates: response.currencyExchangeRates })
            this.$store.dispatch('setInvitationRequired', { invitationRequired: response.invitationRequired })
        })
    },
    computed: {
        ...authComputed,
        showAppBar() {
            return this.loggedIn || !['Home', 'UnlockAccess'].includes(this.$route.name)
        },
        isCookiesAllowed() {
            return this.allowCookies;
        }
    },
    methods: {
        goHome() {
            if (this.$route.path !== '/') {
                this.$router.push({ name: 'Home' });
            }
        },
        feedbackCallback({ type, text }) {
            this.$refs.iSySnackbar.show({ type, text, duration: 3000 })
        },
        editSubscriptionAsked() {
            this.$router.push({ name: 'Subscription' }).catch(() => { });
        },
        helpAsked() {
            this.$refs.helpDialog.dialog = true;
        },
        showOverlay() {
            this.overlay = true;
        },
        hideOverlay() {
            this.overlay = false;
        }
    },
    watch: {
        $route(to, from) {
            const authUser = localStorage.getItem("AUTH_USER");
            const recaptcha = this.$recaptchaInstance;
            if (recaptcha) {
                if (to.meta.recaptcha === true || (to.meta.recaptcha === 'notAuth' && !authUser)) {
                    recaptcha.showBadge();
                }
                else {
                    recaptcha.hideBadge();
                }
            }
        }
    }
};
</script>

<style>
html {
	overflow: auto;
    height: 100%;
}
body {
    height: 100%;
}
.fbanner{
	background-color: dodgerblue;
}
.menuLogo{
	height: 40px;
}
.CompName{
    display: flex;
	cursor: pointer;
	color: var(--darkBG);
	font-weight: bold;
	font-size: larger;
	font-family: inherit;
}
.v-toolbar__content, .v-toolbar__extension {
    justify-content: center;
}
.v-app-bar.v-app-bar--fixed {
    z-index: 30;
}
.theme--dark.v-toolbar.v-sheet.dialog-toolbar-color,
.theme--dark.v-app-bar.v-toolbar.v-sheet.dialog-toolbar-color{
    background-color: var(--agora);
}
.theme--light.v-chip:not(.v-chip--active).bg-t200, .theme--dark.v-chip:not(.v-chip--active).bg-t200, .bg-t200{
    background-color: var(--t200);
    border-color: var(--t200);
    color: #FFF;
}
.theme--light.v-chip:not(.v-chip--active).bg-t200-sell, .theme--dark.v-chip:not(.v-chip--active).bg-t200-sell, .bg-t200-sell{
    background-color: var(--t200-sell);
    border-color: var(--t200-sell);
    color: #FFF;
}
.theme--light.v-chip:not(.v-chip--active).bg-t400-sell, .theme--dark.v-chip:not(.v-chip--active).bg-t400-sell, .bg-t400-sell{
    background-color: var(--t400-sell);
    border-color: var(--t400-sell);
    color: #FFF;
}
.theme--light.v-chip:not(.v-chip--active).bg-t400, .theme--dark.v-chip:not(.v-chip--active).bg-t400, .bg-t400{
    background-color: var(--t400);
    border-color: var(--t400);
    color: #FFF;
}
.theme--light.v-chip:not(.v-chip--active).bg-t600, .theme--dark.v-chip:not(.v-chip--active).bg-t600, .bg-t600{
    background-color: var(--t600);
    border-color: var(--t600);
    color: #FFF;
}
.theme--light.v-chip:not(.v-chip--active).bg-t800, .theme--dark.v-chip:not(.v-chip--active).bg-t800, .bg-t800{
    background-color: var(--t800);
    border-color: var(--t800);
    color: #FFF;
}
.t000{
	color: var(--t000);
}
.t200{
	color: var(--t200);
}
.t200-sell{
	color: var(--t200-sell);
}
.t400-sell{
	color: var(--t400-sell);
}
.t400{
	color: var(--t400);
}
.t600{
	color: var(--t600);
}
.t800{
	color: var(--t800);
}
:root {
	--t000: #999;
    --t000-bg: #ddd;
	--t200: #999;
    --t200-bg: #ddd;
	--t200-sell: #800000;
    --t200-sell-bg: #ffb3b3;
	--t400-sell: #e60000;
    --t400-sell-bg: #ffb3b3;
	--t400: #999;
    --t400-bg: #ddd;
	--t600: #39c640;
    --t600-bg: #c4eec6;
	--t800: #1d6320;
    --t800-bg: #c4eec6;
	--cardHighlight: #CEEEFD;
	--sectionTitle: #b1bfce;
	--darkBG: #374049;
	--lightBG: #d9e0e7;
	--unsedColor: #eee;
	--hoverBG: #e6f2ff;
    --agora: dodgerblue;
    --agoraLight: #80bfff;
    --agoraBG: #e6f2ff;
    --midGray: #777;
    --superLightBG: #f2f2f2;
}
</style>
<style lang="scss" scoped>
@import "assets/styles/breakpoints.scss";
.mainAppBar{
    display: flex;
    align-items: center;
    gap: 15px;
    width: $xlarge;
}
.mainApp {
    width: $xlarge;
    align-self: center;
}
@media screen and (max-width: $xlarge) {
    .mainAppBar{
        width: 100%;
    }
    .mainApp {
        width: 100%;
    }
}
@media screen and (max-width: $small) {
    ::v-deep .v-toolbar__content, .v-toolbar__extension {
        padding: 4px 8px;
    }
    .mainAppBar{
        gap: 10px;
    }
}
</style>