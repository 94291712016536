<template>
    <div class="chart">
        <div class="chartHeader">
            <v-btn-toggle v-model="activeInterval" mandatory dense :tile="largeDisplay" :group="largeDisplay" color="primary" @change="intervalChanged">
                <v-btn v-for="(interval, index) in intervals" :key="index" :value="interval.key" :small="!largeDisplay">
                    {{interval.quantity}}{{$t(`units.${interval.unit}Abbrev`)}}
                </v-btn>
            </v-btn-toggle>
            <div class="chartTitle">{{title}}</div>
        </div>
        <apexchart ref="stockChart" width="100%" type="area" :options="chartOptions" :series="series" class="stockChart"></apexchart>
        <div v-if="emptySeries" class="emptySeries">{{ $t('stockPage.quote.emptyEvolutionPITs') }}</div>
    </div>
</template>

<script>
import DateHelper from '../../infra/DateHelper';
import StockEvolutionService from '../../services/StockEvolutionService';
import StockHtmlHelper from '../stocks/StockHtmlHelper';
import StockChartBuilder from './StockChartBuilder';
export default {
    props: ['stock'],
    data () {
        return {
            title: undefined,
            msg: undefined,
            series: [],
            activeInterval: undefined,
            intervals: [],
            chartOptions: {
                xaxis: {
                    type: 'datetime'
                }
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
        StockEvolutionService.getAvailableIntervals().then(data => {
            this.intervals = data;
            this.activeInterval = data[2];
        });
        this.chartBuilder = new StockChartBuilder({
            yAxisLabelFormatter: price => { return this.stockHtmlHelper.formatPrice(price); },
            isMobile: this.$vuetify.breakpoint.mobile
        });
    },
    computed: {
        largeDisplay() {
            return !this.$vuetify.breakpoint.mobile;
        },
        emptySeries() {
            return this.series.length === 0 || this.series[0].data.length === 0;
        }
    },
    methods: {
        setTitle({ data }) {
            if (!data || data.length === 0) {
                this.title = new Date().toDateString();
            }
            else {
                const ds = DateHelper.toSimpleDate(data[0][0]);
                const de = DateHelper.toSimpleDate(data[data.length - 1][0]);
                if (ds === de) {
                    this.title = ds;
                } else {
                    this.title = this.$t('stockPage.quote.date1toDate2', [ds, de]);
                }
            }
        },
        setData({ name, data, previousClose }) {
            this.setTitle({ data });
            const hourScale = this.activeInterval === 'day';
            const chart = this.chartBuilder.buildChart({ name, data, activeInterval: this.activeInterval, previousClose, locale: this.$i18n.locale, hourScale });
            this.chartOptions = chart.options;
            this.series = chart.series;
        },
        reset() {
            this.series = [];
            this.chartOptions = {
                xaxis: {
                    type: 'datetime'
                }
            };
        },
        intervalChanged() {
            StockEvolutionService.getInterval({ stockId: this.stock.stockId, interval: this.activeInterval }).then(response => {
                const sepit = response.stockEvolutionPITs
                const evoPITs = sepit.evolutionPITs;
                const data = evoPITs.filter(e => e.price).map(s => {
                    return [new Date(s.dateTime + 'Z').toLocaleString('en'), s.price]
                })
                const previousClose = response.previousClosePrice;
                this.setData({
                    name: this.stock.symbol,
                    data,
                    previousClose
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.chart {
    position: relative;
    .chartHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F5F5;
        font-size: small;
        padding: 0 10px;
    }
    .stockChart {
        display: flex;
        justify-content: center;
        background-color: #FFF;
    }
    .emptySeries {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        font-weight: bold;
        background-color: #FFF;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--v-agorabg-base);
    }
    @media screen and (max-width: 750px)  {
        ::v-deep .v-btn:not(.v-btn--round).v-size--small {
            min-width: unset;
        }
        .chartHeader {
            flex-direction: column;
            gap: 5px;
            padding: 8px;
            text-align: start;
        }
        .chartTitle {
            font-size: smaller;
        }
    }
}
</style>
