<template>
    <div class="policies">
        <Policy v-for="(policy, index) in policies" :key="index" :policy="policy"></Policy>
    </div>
</template>

<script>
import Policy from './Policy.vue'
export default {
    props: ["policies"],
	components: { Policy }
}
</script>

<style lang="scss" scoped>
.policies{
    display: flex;
    flex-direction: column;
    gap: 20px;
    *:not(:last-child) {
        border-bottom: 1px solid #ccc; /* Ligne entre chaque objet Policy */
        padding-bottom: 20px; /* Optionnel : espace entre la ligne et le contenu */
    }
}
</style>