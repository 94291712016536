import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[(_vm.showAppBar)?_c(VAppBar,{attrs:{"app":"","color":"white"}},[_c('div',{staticClass:"mainAppBar"},[_c('div',{staticClass:"CompName",on:{"click":_vm.goHome}},[_c('img',{staticClass:"menuLogo",attrs:{"src":require("@/assets/Invagora_small_white.png")}})]),_c(VSpacer),(_vm.loggedIn)?_c('AppBarInfos'):_vm._e(),_c('SubscriptionButtons'),(false)?_c('HelpDialog',{ref:"helpDialog"}):_vm._e(),(_vm.loggedIn)?_c('Avatar',{on:{"editSubscriptionAsked":_vm.editSubscriptionAsked,"helpAsked":_vm.helpAsked}}):_vm._e()],1)]):_vm._e(),_c(VMain,{staticClass:"mainApp"},[(_vm.loggedIn)?_c('AppMessages'):_vm._e(),(_vm.loggedIn && !_vm.$vuetify.breakpoint.mobile)?_c('BasicFeedback',{on:{"feedbackCallback":_vm.feedbackCallback}}):_vm._e(),_c('SySnackbar',{ref:"iSySnackbar"}),_c('router-view'),(!_vm.isCookiesAllowed)?_c('CookiePolicyWidget'):_vm._e()],1),_c(VOverlay,{attrs:{"value":_vm.overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }