import User from './User'

class AuthenticatedUser extends User {
  constructor({ userId, email, firstname, lastname, imageUrl, token, expiration }) {
    super({ userId, email, firstname, lastname, imageUrl });
    this._token = token;
    this._expiration = expiration;
  }

  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  get expiration() {
    return this._expiration;
  }

  set expiration(value) {
    this._expiration = value;
  }

  isExpired() {
    const now = new Date();
    const exp = new Date(this._expiration);
    return now > exp;
  }

  serialize() {
    const serialized = JSON.parse(super.serialize());
    serialized.token = this._token;
    serialized.expiration = this._expiration;
    return JSON.stringify(serialized);
  }
}

export default AuthenticatedUser
