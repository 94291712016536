<template>
    <div class="pagePolicy">
        <div class="pageInfos">
            <div class="policyPanel">
                <div class="langSwitcher">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :dark="true"
                                :small="$vuetify.breakpoint.xs"
                                :large="large !== undefined ? large : false"
                            >
                                <font-awesome-icon :icon="icons.lang" style="--fa-secondary-color: #000;" class="fa-2x mr-3"/>
                                {{label}}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="key in availableLocales"
                                :key="`lang${key}`"
                                @click="lang = key"
                            >
                                <v-list-item-title>{{ locales[key] }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
                <div class="policyTitle">{{title}}</div>
                <div class="policyLastUpdated">
                    <font-awesome-icon :icon="icons.calendar" class="fa-fw fa-lg"></font-awesome-icon>
                    {{lastUpdate}}
                </div>
                <div class="policyDescription">
                    <Policies :policies="getLocalizedSections"></Policies>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { faCalendarAlt, faLanguage } from "@fortawesome/pro-light-svg-icons"
import Policies from './Policies.vue';
import PoliciesMixin from './PoliciesMixin';
import privacy from './privacy.json';
import DateHelper from "../../infra/DateHelper";
export default {
	components: { Policies },
    mixins: [PoliciesMixin],
    data() {
        return {
            icons: {
                calendar: faCalendarAlt,
                lang: faLanguage
            },
            privacy: privacy,
            locales: {
                fr: "Français",
                en: "English"
            },
            lang: this.$i18n.locale
        }
    },
    computed: {
        label() {
            return this.locales[this.lang]
        },
        availableLocales() {
            return Object.keys(this.locales);
        },
        large() {
            return this.$vuetify.breakpoint.lg
        },
        getLocalizedSections() {
            return privacy.content[this.lang].sections;
        },
        title() {
            return privacy.content[this.lang].title
        },
        lastUpdate() {
            const d = DateHelper.toLongDate({ stringDate: privacy.updateDate, locale: this.lang })
            return `${privacy.content[this.lang].updateDate} ${d}`
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/policies.scss";
</style>