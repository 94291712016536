import { faMinusSquare, faPen, faPlusSquare, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import StockHtmlHelper from '../stocks/StockHtmlHelper'
import DateHelper from '../../infra/DateHelper'
var PortfolioStockLotWidgetMixin = {
    data() {
        return {
            icons: {
                edit: faPen,
                delete: faTrashAlt,
                expand: faPlusSquare,
                collapse: faMinusSquare
            },
            expandAll: false,
            expanded: [],
            lotType: 0,
            stockHtmlHelper: undefined
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        isStockExistsInPortfolio() {
            return this.selectedPortfolio.stocks.some(s => s.stockId === this.stock.stockId)
        },
        selectedPortfolio() {
            return this.$store.getters.portfolioList.find(p => p.id === this.portfolioId)
        },
        portfolioStock() {
            return this.selectedPortfolio.stocks.find(s => s.stockId === this.stock.stockId)
        },
        portfolioStockLots() {
            const stockInPortfolio = this.selectedPortfolio.stocks.find(s => s.stockId === this.stock.stockId)
            if (stockInPortfolio) {
                return stockInPortfolio.lots.sort((a, b) => a.dateOfTransaction > b.dateOfTransaction)
            }
            return []
        },
        hasLot() {
            return this.portfolioStockLots.length > 0
        },
        stockPrice() {
            return this.stock.lastPrice
        }
    },
    methods: {
        addStockLot(data) {
            this.$store.dispatch('addPortfolioStockLot', data).catch(error => {
                this.$refs.iAlert.error({ errorCode: error[0].errorCode })
            }).finally(() => {
                this.dialog = false
            })
        },
        editLot({ portfolioStockTransationType, lotId, quantity, averageTransactionPrice, dateOfTransaction, note }) {
            const data = { portfolioId: this.portfolioId, stockId: this.stock.stockId, transitionType: portfolioStockTransationType, lotId, quantity, averageTransactionPrice, dateOfTransaction, note }
            this.$refs.editStockLotDialog.show(data)
        },
        saveLotAsked(data) {
            data.portfolioId = this.portfolioId
            data.stockId = this.stock.stockId
            if (this.isStockExistsInPortfolio) {
                const action = data.lotId ? 'updatePortfolioStockLot' : 'addPortfolioStockLot'
                this.$store.dispatch(action, data).then(response => {
                    this.$refs.editStockLotDialog.cancel()
                })
            }
            else {
                this.$store.dispatch('addPortfolioStock', data).then(() => {
                    this.addStockLot(data)
                }).catch(error => {
                    console.error(error)
                    this.$refs.iAlert.error({ errorCode: error[0].errorCode })
                })
            }
        },
        deleteLotAsked({ lotId }) {
            this.$refs.confirmDelete.open({
                title: this.$t('UserStockWatchingList.portfolio.actions.deleteLot.title'),
                text: this.$t('UserStockWatchingList.portfolio.actions.deleteLot.text'),
                data: { lotId }
            })
        },
        deleteLotAnswered({ response, data }) {
            if (response) {
                const lotData = { portfolioId: this.portfolioId, stockId: this.stock.stockId, lotId: data.lotId }
                this.$store.dispatch('deletePortfolioStockLot', lotData).then((updatedPortfolio) => {
                    this.$emit('portfolioUpdated', { updatedPortfolio })
                })
                this.$refs.editStockLotDialog.cancel()
            }
        },
        moveLotAsked({ lotId }) {
            this.$refs.moveStockDialog.show()
        },
        moveStock({ stockId, fromPortfolioId, toPortfolioId }) {
            this.$store.dispatch('movePortfolioStock', { stockId, fromPortfolioId, toPortfolioId }).then((updatedPortfolios) => {
                //this.$emit('portfolioUpdated', { updatedPortfolio })
            })
        },
        formattedDate(date) {
            return date ? DateHelper.toSimpleDate(date) : ''
        },
        priceByShare(price) {
            return this.stockHtmlHelper.formatPriceWithDigit(price, 2)
        },
        lotCost({ quantity, averageTransactionPrice }) {
            const price = quantity * averageTransactionPrice
            return this.stockHtmlHelper.formatPrice(price)
        },
        lotValue({ portfolioStockTransationType, quantity, averageTransactionPrice }) {
            if (portfolioStockTransationType === 'sell') return '-'
            const price = quantity * this.stockPrice
            return this.stockHtmlHelper.formatPrice(price)
        },
        gainPrct({ standardizedTotalGainPourcentage }) {
            const change = standardizedTotalGainPourcentage
            return `${change.toFixed(2)}%`
        },
        gain({ standardizedTotalGain }) {
            const gain = standardizedTotalGain;
            return this.stockHtmlHelper.formatPriceWithDigit(gain, 2)
        },
        gainClass({ standardizedTotalGain }) {
            return `psl${standardizedTotalGain < 0 ? ' negative' : standardizedTotalGain > 0 ? ' positive' : ''}`
        }
    }
}

export default PortfolioStockLotWidgetMixin