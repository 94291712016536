<template>
    <div class="policiesAndContactUs">
        <div class="pcLeft">
            <v-btn text small color="primary" @click="$router.push({ name: 'PrivacyPolicy' })">{{ $t(`policies.privacy.title`) }}</v-btn>
            <v-btn text small color="primary" @click="$router.push({ name: 'TermsAndConditions' })">{{ $t(`policies.termsAndConditions.title`) }}</v-btn>
        </div>
        <div class="pcRight">
            <v-btn text small color="primary" href="mailto:admins@synaxy.com">{{ $t(`dictio.contactUs`) }}</v-btn>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.policiesAndContactUs{
    max-width: 800px;
    padding: 16px 0;
    flex: auto;
    border-top: solid 1px var(--agora);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pcLeft {
        display: flex;
        gap: 15px;
    }
    .pcRight {
        display: flex;
        gap: 15px;
    }
    @media screen and (max-width: $phone) {
        width: 100%;
        flex-direction: column;
        gap: 15px;
        .pcLeft {
            flex-direction: column;
        }
    }
}
</style>