<template>
    <div class="accountPage">
        <div class="apHeader">
            <font-awesome-icon :icon="icons.cog" :class="`fa-${iconSize}`"></font-awesome-icon>
            <div class="apTitle">{{$t('account.header.title')}}</div>
        </div>
        <div class="apBody">
            <AccountSections></AccountSections>
        </div>
        <v-footer color="secondary lighten-1" padless>
            <v-row
            justify="center"
            no-gutters
            align="center"
            >
                <v-btn
                    v-for="link in links"
                    :key="link.name"
                    color="white"
                    text
                    rounded
                    small
                    class="my-2"
                    @click="$router.push({ name: link.route })"
                >
                    {{ $t(`settings.links.${link.name}`) }}
                </v-btn>
                <v-spacer></v-spacer>
                <div class="program">
                    <span>{{$t('program.stripeClimatePartner')}}</span>
                    <v-img :src="require('@/assets/Stripe Climate Badge - Small.png')" position="center center" contain width="25" height="25" class="flex-grow-0"/>
                </div>
                <div class="cie">
                    <span>{{ new Date().getFullYear() }} — <strong>Invagora</strong></span>
                </div>
            </v-row>
        </v-footer>
    </div>
</template>

<script>
import { faUserCog } from "@fortawesome/pro-duotone-svg-icons"
import AccountSections from "./AccountSections.vue";

export default {
    data() {
        return {
            icons: {
                cog: faUserCog
            },
            links: [
                {
                    name: 'termsAndConditions',
                    route: 'TermsAndConditions'
                },
                {
                    name: 'privacyPolicy',
                    route: 'PrivacyPolicy'
                },
                {
                    name: 'cookiePolicy',
                    route: 'CookiePolicy'
                }
            ]
        };
    },
    components: { AccountSections },
    computed: {
        iconSize() {
            return this.$vuetify.breakpoint.mobile ? 'lg' : '3x'
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/breakpoints.scss";
.accountPage{
    display: flex;
    flex-direction: column;
    font-size: large;
    height: 100%;
    background-color: var(--lightBG);
    position: absolute;
    width: 100%;
    left: 0;
    .apHeader{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px;
        font-size: xx-large;
        .apTitle{
            margin-left: 30px;
            text-transform: uppercase;
        }
    }
    .apBody{
        width: 600px;
        margin: 0 auto;
        margin-bottom: 50px;
        flex-grow: 1;
    }
    .program {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: small;
        color: #FFF;
        margin-right: 10px;
    }
    .cie {
        font-size: small;
        margin: 8px 0;
        padding: 0 16px;
        display: flex;
        color: #FFF;
    }
    @media screen and (max-width: $tablet) {
        .apHeader {
            font-size: large;
            padding: 15px;
        }
        .apBody {
            width: 100%;
            padding: 0 10px;
        }
    }
}
</style>
