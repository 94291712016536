import AuthenticatedUser from '../../models/AuthenticatedUser'

class AuthenticatedUserConverter {
    static convert(authUser) {
        return new AuthenticatedUser({
            userId: authUser.user.userId,
            email: authUser.user.email,
            firstname: authUser.user.firstName,
            lastname: authUser.user.lastName,
            imageUrl: authUser.user.imageUrl,
            token: authUser.tokenInfo.rawToken,
            expiration: authUser.tokenInfo.expiration
        });
    }
}

export default AuthenticatedUserConverter
