<template>
    <v-dialog v-model="dialog" scrollable max-width="1300px">
        <div class="stockFullCardCont">
            <div class="dialogHeader">
                <StockPageHeader></StockPageHeader>
            </div>
            <div class="dialogBody">
                <div class="stockInfos">
                    <!-- <StockOverview :stock="stockInfos" :key="stockId" class="siOverview"></StockOverview> -->
                    <StockChart ref="stockChart" class="siChart" :stock="stock"></StockChart>
                </div>
                <div v-if="showStockInfos">
                    <div class="transactionSectionTitle">{{ $t('UserStockWatchingList.portfolio.stockLots') }}</div>
                    <PortfolioStockLotWidget v-if="$vuetify.breakpoint.smAndUp" :portfolioId="portfolioId" :stockLots="stock.lots" :stock="selectedStock" @stockLotAdded="stockLotAdded" @portfolioUpdated="portfolioUpdated"></PortfolioStockLotWidget>
                    <PortfolioStockLotWidgetMobile v-else :portfolioId="portfolioId" :stockId="stock.stockId" :stockinfo="selectedStock"></PortfolioStockLotWidgetMobile>
                </div>
            </div>
            <div class="dialogFooter">
                <v-spacer></v-spacer>
                <v-btn
                    color="secondary"
                    fab
                    small
                    dark
                    @click="close"
                    class="ml-3"
                >
                    <font-awesome-icon :icon="icons.close" class="fa-fw fa-lg" color="primary"></font-awesome-icon>
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import StockService from '../../services/StockService';
import PortfolioStockLotWidget from './PortfolioStockLotWidget.vue';
import PortfolioStockLotWidgetMobile from './PortfolioStockLotWidgetMobile.vue';
import StockChart from '../controls/StockChart.vue';
import StockPageHeader from '../../views/Stock/StockPageHeader.vue';
export default {
    props: ['portfolioId', 'stock'],
    components: { PortfolioStockLotWidget, PortfolioStockLotWidgetMobile, StockChart, StockPageHeader },
    data() {
        return {
            dialog: false,
            icons: {
                close: faTimes
            },
            showStockLoader: false,
            showStockInfos: false,
            foundSymbol: undefined,
            stockId: undefined,
            selectedStock: undefined,
            stockInfos: undefined
        }
    },
    methods: {
        symbolSelected({ stockId, normalizedTicker, exchange }) {
            this.foundSymbol = normalizedTicker
            this.showStockLoader = true
            StockService.getStock({ symbol: this.foundSymbol, plateformCustomExchange: exchange }).then((stock) => {
                if (stock !== undefined) {
                    this.showStockLoader = false;
                    this.showStockInfos = true;
                    this.selectedStock = stock;
                }
            }).catch(err => { console.error(err); });
            this.$store.dispatch('getStockCompleteInfo', { stockId: this.stockId }).then(result => {
                this.stockInfos = result;
                this.fillStockEvolutionData();
            });
        },
        fillStockEvolutionData() {
            this.loaded = true;
            const evo = this.stockInfos.dayStockEvolution;
            if (evo) {
                const data = evo.stockEvolutionPITList.map(s => { return [Date.parse(`${s.dateTime}Z`), s.price]; })
                this.$refs.stockChart.setData({
                    name: this.stockInfos.symbol,
                    data,
                    change: this.stockInfos.change,
                    previousClose: evo.previousClose
                })
            }
        },
        stockLotAdded(lotAdded) {
            const lot = {
                lotId: lotAdded.lotId,
                quantity: lotAdded.quantity,
                averageShareCost: lotAdded.averageShareCost,
                buyDate: lotAdded.buyDate,
                note: lotAdded.note
            }
            this.$store.dispatch('addPortfolioStockLot', { portfolioId: this.portfolioId, stockId: this.stock.stockId, lot })
        },
        portfolioUpdated({ updatedPortfolio }) {
            if (updatedPortfolio.stocks.length === 0) {
                this.close()
            }
        },
        show(stock) {
            this.stockId = stock.stockId
            this.symbolSelected({ stockId: stock.stockId, normalizedTicker: stock.stockDateTimeSnapshot.symbol, exchange: stock.stockDateTimeSnapshot.contextualExchangeCode })
            this.dialog = true
        },
        close() {
            this.dialog = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
::v-deep .v-dialog {
    margin: 5px;
}
.stockFullCardCont {
    background-color: #FFF;
    border-radius: 10px;
    >* {
        padding: 15px;
    }
    .dialogHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .dialogBody {
        display: flex;
        flex-direction: column;
        gap: 15px;
        overflow-y: auto;
        max-height: 600px;
        .transactionSectionTitle {
            font-size: 1.1em;
            padding: 10px;
            background-color: var(--v-lightGrayBG-base);
        }
        .stockInfos {
            display: flex;
            gap: 20px;
            .siOverview {
                flex: 2;
            }
            .siChart {
                flex: 3;
            }
        }
    }
    .dialogFooter {
        display: flex;
    }
    @media screen and (max-width: $tablet) {
        .dialogHeader {
            padding: 12px;
        }
        .dialogBody {
            padding: 0 12px;
            .transactionSectionTitle {
                font-size: 0.9em;
                padding: 5px;
            }

            .stockInfos {
                flex-direction: column;
            }
        }
        .dialogFooter {
            padding: 12px;
        }
    }
    @media screen and (min-height: 1000px) {
        .dialogBody {
            max-height: 60vh;
        }
    }
}
</style>