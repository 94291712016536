import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagePolicy"},[_c('div',{staticClass:"pageInfos"},[_c('div',{staticClass:"policyPanel"},[_c('div',{staticClass:"langSwitcher"},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"dark":true,"small":_vm.$vuetify.breakpoint.xs,"large":_vm.large !== undefined ? _vm.large : false}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{staticClass:"fa-2x mr-3",staticStyle:{"--fa-secondary-color":"#000"},attrs:{"icon":_vm.icons.lang}}),_vm._v(" "+_vm._s(_vm.label)+" ")],1)]}}])},[_c(VList,_vm._l((_vm.availableLocales),function(key){return _c(VListItem,{key:`lang${key}`,on:{"click":function($event){_vm.lang = key}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.locales[key]))])],1)}),1)],1)],1),_c('div',{staticClass:"policyTitle"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"policyLastUpdated"},[_c('font-awesome-icon',{staticClass:"fa-fw fa-lg",attrs:{"icon":_vm.icons.calendar}}),_vm._v(" "+_vm._s(_vm.lastUpdate)+" ")],1),_c('div',{staticClass:"policyDescription"},[_c('Policies',{attrs:{"policies":_vm.getLocalizedSections}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }