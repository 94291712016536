<template>
    <div class="policy">
        <div class="policyTitle">{{policy.title}}</div>
        <div class="policyPara" v-if="intro" v-html="intro"></div>
        <div class="policyPara">
            <ul class="pl-6">
                <li class="py-1" v-for="(point, index) in policy.content" :key="index" v-html="point"></li>
            </ul>
        </div>
        <div class="policyPara" v-if="end" v-html="end"></div>
    </div>
</template>

<script>
export default {
    props: ["policy"],
    computed: {
        intro() {
            return this.policy.intro ? this.policy.intro : '';
        },
        end() {
            return this.policy.end ? this.policy.end : '';
        }
    }
}
</script>

<style lang="scss" scoped>
.policy {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .policyTitle {
        font-size: 24px;
        font-weight: bold;
    }
    .policyPara {
        font-size: 16px;
    }
}
</style>