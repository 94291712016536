<template>
    <v-dialog v-model="dialog" max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="editForm" v-model="valid" @submit.prevent="">
                    <div class="searchCont">
                        <div v-if="editMode" class="infoStockCard">
                            <PricedStockCard :entry="editedItem"></PricedStockCard>
                        </div>
                        <div v-else class="searchStockInfos">
                            <div v-if="!showStockInfos" class="searchStock">
                                <StockSearcher @symbolSelected="symbolSelected" @symbolCleared="symbolCleared"></StockSearcher>
                            </div>
                            <div class="infoStockCard">
                                <v-skeleton-loader v-if="showStockLoader" type="list-item-two-line"></v-skeleton-loader>
                                <PricedStockCard v-if="showStockInfos" :entry="editedItem" :closable="true" @close="closePSC"></PricedStockCard>
                            </div>
                        </div>
                    </div>
                    <v-card elevation="2">
                        <div class="sectionTitle">
                            <span>{{$t('thresholds.title')}}</span>
                            <div class="t-infos">
                                <v-btn
                                    color="primary"
                                    icon
                                    @click="toggleHelp">
                                    <font-awesome-icon :icon="icons.help" class="fa-2x" />
                                </v-btn>
                            </div>
                        </div>
                        <v-expand-transition>
                            <v-card
                            v-show="showHelp"
                            width="100%"
                            class="sectionDesc"
                            >{{$t('thresholds.description')}} {{$t(`thresholds.desc-${listType}`)}}</v-card>
                        </v-expand-transition>
                        <div class="editPanel">
                            <BuyEditionPanel v-if="listType === 'buy'" :thresholds="thresholds" ref="buyEditionPanel" @isFormValid="subEditFormValid" @inputChanged="editInputChanged" @submitAsked="submitAsked"></BuyEditionPanel>
                            <SellEditionPanel v-else :thresholds="thresholds" ref="sellEditionPanel" @isFormValid="subEditFormValid" @inputChanged="editInputChanged" @submitAsked="submitAsked"></SellEditionPanel>
                        </div>
                    </v-card>
                    <div v-if="editMode">
                        <div class="sectionSubTitle">{{$t('EditorPanel.actions.quickActions')}}</div>
                        <div class="ed-actions">
                            <div class="ed-action-part" v-for="changeValue in changeValues" :key="`${changeValue}-ed`">
                                <v-btn small outlined rounded color="teal darken-1" dark @click="addToAll(changeValue)"><font-awesome-icon :icon="icons.up" class="fa-fw mr-1"></font-awesome-icon>{{changeValue}}%</v-btn>
                                <v-btn small outlined rounded color="teal darken-1" dark @click="removeToAll(changeValue)"><font-awesome-icon :icon="icons.down" class="fa-fw mr-1"></font-awesome-icon>{{changeValue}}%</v-btn>
                            </div>
                        </div>
                        <v-divider></v-divider>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="editMode"
                    color="red"
                    fab
                    small
                    dark
                    @click="remove"
                >
                    <font-awesome-icon :icon="icons.delete" class="fa-fw fa-lg"></font-awesome-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <SaveCancelButtons ref="saveCancelButtons" align="end" @saveAsked="save" @cancelAsked="close" :enableSave="formValid"></SaveCancelButtons>
            </v-card-actions>
        </v-card>
        <Alert ref="iAlert"></Alert>
    </v-dialog>
</template>

<script>
import PricedStockCard from '../../stocks/PricedStockCard'
import StockHtmlHelper from '../../stocks/StockHtmlHelper'
import StockService from '../../../services/StockService'
import BuyEditionPanel from './BuyEditionPanel'
import SellEditionPanel from './SellEditionPanel'
import StockSearcher from '../../controls/StockSearcher.vue'
import SaveCancelButtons from '../../controls/SaveCancelButtons.vue'
import Alert from '@/components/controls/Alert.vue'
import { faArrowDown, faArrowUp, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faCheck, faTimes, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'
export default {
    data() {
        return {
            icons: {
                delete: faTrashAlt,
                save: faCheck,
                cancel: faTimes,
                up: faArrowUp,
                down: faArrowDown,
                help: faInfoCircle
            },
            changeValues: [1, 2, 5, 10],
            valid: false,
            validThresholds: false,
            showHelp: false,
            dialog: false,
            dialogLoaded: false,
            specificEditionPanel: undefined,
            itemListId: undefined,
            editedItem: { symbol: '' },
            editMode: false,
            listType: 'buy',
            title: '',
            icon: faCircle,
            stockHtmlHelper: undefined,
            showStockInfos: false,
            showStockLoader: false,
            thresholds: {},
            rules: {
                required: value => !!value || this.$t('EditorPanel.errors.required'),
                isSymbol: value => {
                    const pattern = /^([a-zA-Z-0-9.\\-]+)$/;
                    return (
                        pattern.test(value) || !value || this.$t('EditorPanel.errors.invalidSymbol')
                    );
                }
            }
        }
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    components: {
        PricedStockCard,
        BuyEditionPanel,
        SellEditionPanel,
        StockSearcher,
        SaveCancelButtons,
        Alert
    },
    computed: {
        formTitle() {
            return this.editMode ? this.$t('EditorPanel.header.edit.title') : this.$t('EditorPanel.header.add.title')
        },
        formValid() {
            return this.valid && this.validThresholds
        }
    },
    methods: {
        open({ listId, item, type }) {
            this.showStockInfos = false
            this.itemListId = listId;
            this.editMode = !!item.symbol;
            this.editedItem = item;
            this.thresholds = item.getThresholds();
            this.listType = type;
            this.dialog = true;
        },

        close() {
            this.dialog = false;
        },

        serializeThresholds() {
            const thresholds = this.listType === 'buy' ? this.$refs.buyEditionPanel.thresholds : this.$refs.sellEditionPanel.thresholds;
            return {
                T200: parseFloat(thresholds.t200 || 0),
                T400: parseFloat(thresholds.t400 || 0),
                T600: parseFloat(thresholds.t600 || 0),
                T800: parseFloat(thresholds.t800 || 0)
            }
        },

        save() {
            if (this.valid && this.specificEditionPanel.isFormValid()) {
                const action = this.editMode ? 'updateStockInUserWatchList' : 'addStockInWatchList';
                const newEntry = this.editedItem.rawData;
                // Pour matcher avec le controller
                newEntry.threasholds = this.serializeThresholds();
                newEntry.plateformCustomExchange = newEntry.exchange;

                this.$store.dispatch(action, { listId: this.itemListId, newEntry, listType: this.listType }).then(() => {
                    this.editedItem = {};
                    this.close()
                }).catch(error => {
                    this.$refs.iAlert.error(error[0]);
                });
            }
        },

        remove() {
            this.$emit('removeSymbol', this.editedItem);
        },

        subEditFormValid(status) {
            this.validThresholds = status.valid
        },

        editInputChanged(status) {
            this.validThresholds = status.valid
        },

        checkFormStatus(status) {
            this.validThresholds = status !== undefined ? status : this.specificEditionPanel.isFormValid();
        },

        submitAsked(status) {
            if (this.valid && status.valid) {
                this.save();
            }
            else {
                console.log('Invalide!!');
            }
        },

        symbolSelected(selection) {
            if (this.$refs.editForm.validate()) {
                this.editedItem.symbol = selection.normalizedTicker;
                this.showStockLoader = true;
                StockService.getStock({ symbol: this.editedItem.symbol, plateformCustomExchange: selection.exchange }).then((stock) => {
                    if (stock !== undefined) {
                        this.showStockLoader = false;
                        this.showStockInfos = true;
                        this.editedItem = stock;
                        this.checkFormStatus();
                    }
                }).catch(err => { console.error(err); });
            }
        },

        symbolCleared() {
            this.showStockInfos = false;
            this.editedItem = {};
        },

        getthresholdColor(score) {
            return this.stockHtmlHelper.getBadgeColor(score);
        },

        resetAllForm() {
            this.$refs.editForm.reset();
            this.specificEditionPanel.resetForm();
        },

        addToAll(value) {
            for (const t in this.thresholds) {
                if (Object.hasOwnProperty.call(this.thresholds, t)) {
                    if (this.thresholds[t] !== '') {
                        const tmpT = this.thresholds[t] + (this.thresholds[t] * (value / 100));
                        this.thresholds[t] = this.stockHtmlHelper.normalizePrice(tmpT);
                    }
                }
            }
        },

        removeToAll(value) {
            for (const t in this.thresholds) {
                if (Object.hasOwnProperty.call(this.thresholds, t)) {
                    if (this.thresholds[t] !== '') {
                        const tmpT = this.thresholds[t] - (this.thresholds[t] * (value / 100));
                        this.thresholds[t] = this.stockHtmlHelper.normalizePrice(tmpT);
                    }
                }
            }
        },
        toggleHelp() {
            this.showHelp = !this.showHelp;
        },
        closePSC() {
            this.showStockInfos = false
        }
    },
    watch: {
        dialog(val) {
            if (val) {
                setTimeout(() => {
                    this.specificEditionPanel = this.$refs[`${this.listType}EditionPanel`];
                    if (!this.editMode) {
                        this.resetAllForm();
                    }
                    //this.$refs.saveCancelButtons.enableSave(this.editMode);
                    this.dialogLoaded = true;
                }, 200);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
    margin: 5px;
    .v-card>* {
        padding-left: 12px;
        padding-right: 12px;
    }

}
.searchCont {
    display: flex;
    margin-bottom: 10px;
    .searchStockInfos {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
</style>
<style>
.v-card__actions {
	padding: 0 24px 20px;
}
.symbolInput input{
    text-transform: uppercase;
}
.infoStockCard{
    padding: 12px;
    width: 100%;
    font-size: large;
    background-color: #EFEFEF;
}
.infoStockCard:empty{
    display: none;
}
.searchStock {
    width: -webkit-fill-available;
}
.sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: larger;
    font-weight: 500;
    padding: 10px 15px;
}
.sectionSubTitle {
    font-size: small;
    text-align: center;
}
.sectionDesc {
    padding: 10px;
    margin-bottom: 10px;
    font-size: small;
}
.editPanel {
    display: flex;
    align-items: center;
    padding: 0 15px 15px;
}
.ed-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.ed-action-part {
    display: flex;
    gap: 10px;
}
@media screen and (max-width: 750px) {
    .ed-actions {
        padding: 0;
    }
    .ed-action-part>.v-btn:not(.v-btn--round).v-size--small {
        padding: 0 7px;
        width: fit-content;
    }
    .container{
        padding: 0;
        padding-bottom: 10px;
    }
    .v-subheader{
        padding: 0;
        height: 25px;
        font-size: large;
    }
    .col-6 {
        padding: 0 10px;
    }
}
</style>
