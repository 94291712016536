import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons"
var PoliciesMixin = {
    data () {
        return {
            icons: {
                calendar: faCalendarAlt
            }
        }
    }
}

export default PoliciesMixin