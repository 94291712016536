<template>
    <div :class="`overviewPortfolioCard ${getCardClass}`" @click="viewStockLot">
        <div class="opcStockInfos">
            <div class="opcSymbol">{{ symbol }}</div>
            <StockDayChange v-if="stock.stockDateTimeSnapshot" :stock="stock" class="opcStockDayChange"></StockDayChange>
        </div>
        <StockCardPriceInfos :stockPriceInfos="stockPriceInfos"></StockCardPriceInfos>
    </div>
</template>

<script>
import StockCardPriceInfos from '../../controls/StockCardPriceInfos.vue';
import StockDayChange from '../../stocks/StockDayChange.vue';
import StockPriceInfoConverter from '../../../infra/converters/StockPriceInfoConverter';
export default {
    props: ["stock"],
    components: { StockDayChange, StockCardPriceInfos },
    computed: {
        getCardClass() {
            return this.stock.statistics.dayChange < 0 ? "down" : this.stock.statistics.dayChange > 0 ? 'up' : '';
        },
        symbol() {
            return this.stock && this.stock.stockDateTimeSnapshot && this.stock.stockDateTimeSnapshot.symbol ? this.stock.stockDateTimeSnapshot.symbol : '';
        },
        stockPriceInfos() {
            if (this.stock.stockDateTimeSnapshot) {
                return StockPriceInfoConverter.convertPortfolioStock(this.stock);
            }
            return {};
        }
    },
    methods: {
        viewStockLot() {
            this.$emit('viewStockLot', { stock: this.stock });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/breakpoints.scss";
.overviewPortfolioCard {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    padding: 10px;
    border: solid 1px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    &.up{
        border-color: var(--t800);
        background-color: #dcefdc;
        &:hover {
            background-color: #cbe7cb;
        }
    }
    &.down{
        border-color: var(--t400-sell);
        background-color: #ffe6e6;
        &:hover {
            background-color: #ffcccc;
        }
    }
    .opcStockInfos {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2em;
        .opcSymbol {
            color: #000;
            font-weight: bold;
        }
        .opcStockDayChange {
            font-size: 1.3em;
        }
    }
    @media screen and (max-width: $small) {
        & {
            padding: 4px;
            font-size: 0.7em;
            gap: 5px;
            .opcStockInfos {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}
</style>