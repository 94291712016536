import UserAccountService from "./UserAccountService";

var UsersSectionMixin = {
    data() {
        return {
            overlay: false,
            headers: [
                { text: this.$t('admin.body.users.fields.firstname'), value: 'firstname' },
                { text: this.$t('admin.body.users.fields.lastname'), value: 'lastname' },
                { text: this.$t('admin.body.users.fields.email'), value: 'email' },
                { text: this.$t('admin.body.users.fields.lang'), value: 'language' },
                { text: this.$t('admin.body.users.fields.date'), value: 'utcDateTime' },
                { text: this.$t('admin.body.users.fields.planName'), value: 'planName' },
                { text: this.$t('admin.body.users.fields.firstAuthentificationMethod'), value: 'firstAuthentificationMethod' },
                { text: this.$t('admin.body.users.fields.lastAcceptedTermsVersion'), value: 'lastAcceptedTermsVersion' }
            ],
            userList: []
        }
    },
    created() {
        this.overlay = true;
        this.getUsersRegistrationInfo().then(result => {
            console.log(result);
            this.userList = result;
            this.overlay = false;
        }).catch((err) => {
            console.error(err.message);
        });
    },
    computed: {
        userListCount() {
            return this.userList.length;
        }
    },
    methods: {
        getUsersRegistrationInfo() {
            return UserAccountService.getUsersRegistrationInfo()
        }
    }
}

export default UsersSectionMixin