import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import UserStockList from '../views/UserStockList'
import AdministrationPage from '../views/Admin/AdministrationPage'
import ResetPasswordRequest from '../views/resetPassword/ResetPasswordRequest'
import ResetPasswordRequestSent from '../views/resetPassword/ResetPasswordRequestSent'
import ResetPasswordForm from '../views/resetPassword/ResetPasswordForm'
import ResetPasswordDone from '../views/resetPassword/ResetPasswordDone'
import RegisterPage from '../views/Registering/RegisterPage'
import Portfolio from '../views/Portfolio/PortfolioPage'
import StockPage from '../views/Stock/StockPage'
import Login from '../views/Login/LoginPage'
import ActivationEmailSent from '../views/Registering/ActivationEmailSent'
import InvitationinRequestReceived from '../views/Registering/InvitationinRequestReceived'
import AccountActivation from '../views/Registering/AccountActivation'
import ServiceOptions from '../services/serviceOptions'
import UnlockAccess from '../views/Access/UnlockAccess'
import SubscriptionSuccess from '@/components/subscriptions/SubscriptionSuccess'
import SubscriptionCancel from '@/components/subscriptions/SubscriptionCancel'
import { testToken } from '../services/AuthenticationService'
import AuthUserJWT from "../infra/AuthUserJWT"
import { invitedVisitorHasVisitedWebsite } from '../services/InvitationService'
import storageService from '@/services/StorageService';
import CookiePolicyPage from '../views/Policies/CookiePolicyPage.vue'
import TermsAndConditionsPage from '../views/Policies/TermsAndConditionsPage.vue'
import PrivacyPolicyPage from '../views/Policies/PrivacyPolicyPage.vue'
import MyAccountPageVue from '../views/Account/MyAccountPage.vue'
import AccountCreatedVue from '../views/Registering/AccountCreated.vue'
import Subscription from '../views/Subscription/SubscriptionPage.vue'
import Dashboard2 from '../views/Dashboard/Dashboard2.vue'

import terms from '../../src/views/Policies/terms.json'
import NotFound404 from '../views/NotFound404.vue'
import store from '../store/store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            recaptcha: 'notAuth',
            checkFirstVisit: true
        }
    },
    {
        path: '/list/:id/:stockId?',
        name: 'List',
        component: UserStockList,
        meta: {
            requiresAuth: true,
            recaptcha: false
        }
    },
    {
        path: '/portfolio/:id',
        name: 'Portfolio',
        component: Portfolio,
        meta: {
            requiresAuth: true,
            recaptcha: false
        }
    },
    {
        path: '/stock/:id',
        name: 'Stock',
        component: StockPage,
        meta: {
            requiresAuth: true,
            recaptcha: false
        }
    },
    {
        path: '/myAccount',
        name: 'MyAccount',
        component: MyAccountPageVue,
        meta: {
            requiresAuth: true,
            recaptcha: false
        }
    },
    {
        path: '/legal',
        component: {
            render: h => h('router-view')
        },
        children: [
            {
                path: 'cookie-policy',
                name: 'CookiePolicy',
                component: CookiePolicyPage
            },
            {
                path: 'terms-conditions',
                name: 'TermsAndConditions',
                component: TermsAndConditionsPage
            },
            {
                path: 'privacy-policy',
                name: 'PrivacyPolicy',
                component: PrivacyPolicyPage
            }
        ]
    },
    {
        path: '/admin',
        name: 'Admin',
        component: AdministrationPage,
        meta: {
            requiresAuth: true,
            isAdmin: true,
            recaptcha: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            recaptcha: 'notAuth'
        }
    },
    {
        path: '/register',
        component: {
            render: h => h('router-view')
        },
        children: [
            {
                path: 'form',
                name: 'RegisterForm',
                component: RegisterPage,
                meta: {
                    recaptcha: true,
                    checkFirstVisit: true
                }
            },
            {
                path: 'activationEmailSent',
                name: 'ActivationEmailSent',
                component: ActivationEmailSent,
                props: true
            },
            {
                path: 'accountCreated',
                name: 'AccountCreated',
                component: AccountCreatedVue
            },
            {
                path: 'activation/confirmEmail',
                name: 'AccountActivation',
                component: AccountActivation
            },
            {
                path: 'InvitationinRequestReceived',
                name: 'InvitationinRequestReceived',
                component: InvitationinRequestReceived,
                props: true
            }
        ]
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: function () {
            return import(/* webpackChunkName: "about" */ '../views/About.vue')
        },
        meta: {
            recaptcha: false
        }
    },
    {
        path: '/resetPassword',
        component: {
            render: h => h('router-view')
        },
        children: [
            {
                path: 'request',
                name: 'ResetPasswordRequest',
                component: ResetPasswordRequest,
                meta: {
                    recaptcha: true
                }
            },
            {
                path: 'sent',
                name: 'ResetPasswordRequestSent',
                component: ResetPasswordRequestSent,
                props: true
            },
            {
                path: 'form',
                name: 'ResetPasswordForm',
                component: ResetPasswordForm,
                meta: {
                    recaptcha: true
                }
            },
            {
                path: 'done',
                name: 'ResetPasswordDone',
                component: ResetPasswordDone
            }
        ]
    }, {
        path: '/unlockAccess',
        name: 'UnlockAccess',
        component: UnlockAccess,
        meta: {
            recaptcha: false
        }
    }, {
        path: '/subscription',
        name: 'Subscription',
        component: Subscription
    }, {
        path: '/subscription/success',
        name: 'SubscriptionSuccess',
        component: SubscriptionSuccess
    }, {
        path: '/subscription/cancel',
        name: 'SubscriptionCancel',
        component: SubscriptionCancel
    }, {
        path: '/d2',
        name: 'Dashboard2',
        component: Dashboard2
    }, {
        path: '*',
        component: NotFound404
    }
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    if (to.name === "UnlockAccess") {
        next();
    }
    else {
        if (to.name === "Home") {
            store.dispatch('resetHistory');
        } else {
            store.dispatch('addToHistory', to);
        }
        const authUser = localStorage.getItem("AUTH_USER");
        ServiceOptions.addAppPreproductionKey({ appPreproductionKey: localStorage.getItem("app-preproduction-key") });
        ServiceOptions.addAppLanguage({ lang: localStorage.getItem("USER_LANG") });
        testToken().then((data) => {
            if (to.matched.some(route => route.meta.requiresAuth)) {
                if (!authUser) {
                    throw new Error('notAuthenticated');
                }
            }
            if (to.matched.some(route => route.meta.isAdmin)) {
                const authUserJWT = new AuthUserJWT(JSON.parse(authUser).token);
                if (!authUserJWT.hasRole('admin')) {
                    throw new Error('notAdmin');
                }
            }
            if (to.matched.some(route => route.meta.checkFirstVisit)) {
                if (to.query.inviteToken) {
                    if (storageService.inviteToken !== to.query.inviteToken) {
                        storageService.inviteToken = to.query.inviteToken;
                        invitedVisitorHasVisitedWebsite({ invitationToken: to.query.inviteToken }).catch(errors => {
                            next(`/?e=${errors[0].errorCode}`).catch(() => { })
                        })
                    }
                }
            }
            if (authUser && storageService.acceptedTermsVersion !== terms.version && to.name !== 'TermsAndConditions') {
                throw new Error('termsNotAccepted');
            }
            next()
        }).catch((err) => {
            const msg = err.message || 'unk'
            switch (msg) {
                case 'notAuthenticated':
                case 'notAdmin':
                    next(`/`)
                    break
                case 'termsNotAccepted':
                    router.push({ name: 'TermsAndConditions', query: { v: terms.version } }).catch(() => { })
                    break
                default:
                    next(`/?e=${msg}`)
                    break
            }
        });
    }
});

export default router
