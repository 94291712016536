import { addStockInUserWatchList, updateStockInUserWatchList, deleteStockFromWatchList } from '../../services/UserStockWatchingDefinitionService'
import { createNewList, getAllListWithEntries, updateListInfo, deleteList } from '../../services/UserStockListService'
import { getListNameFromType } from '../helpers/ListHelper'
import { addPortfolio, addPortfolioStock, addPortfolioStockLot, deletePortfolio, deletePortfolioStockLot, getUserAllPortfolios, importInPortfolio, movePortfolioStock, updatePortfolio, updatePortfolioStockLot } from '../../services/PortfolioService';

const initialState = () => {
    return {
        loaded: {
            buyLists: false,
            sellLists: false,
            portfolios: false
        },
        stockOptionWatchList: [],
        lists: {
            portfolios: [],
            buyLists: [],
            sellLists: []
        },
        bestLists: {
            buys: [],
            sells: []
        },
        focusedLists: []
    };
};
var listDataStore = {
    state: Object.assign({}, initialState()),
    mutations: {
        setUserLists(state, { listType, lists }) {
            state.lists[listType] = lists;
            state.loaded[listType] = true;
        },

        stockOptionWatchList(state, { stockOptionWatchList }) {
            state.stockOptionWatchList = stockOptionWatchList
        },

        addList(state, { listName, listInfos }) {
            state.lists[listName].push(listInfos);
        },

        updatedList(state, { listInfos, listName }) {
            const listToUpdate = state.lists[listName].find(list => list.id === listInfos.id)
            listToUpdate.name = listInfos.name
            listToUpdate.description = listInfos.description
            if (listInfos.currency) {
                listToUpdate.currency = listInfos.currency
            }
        },

        replacePortfolio(state, { portfolio }) {
            state.lists.portfolios = state.lists.portfolios.map(p => {
                if (p.id === portfolio.id) {
                    return portfolio
                }
                return p
            })
        },

        deleteList(state, { listId, listName }) {
            state.lists[listName] = state.lists[listName].filter(list => list.id !== listId);
        },

        addStockInList(state, { listId, item, listName }) {
            state.lists[listName].find(list => list.id === listId).entries.push(item);
        },

        updateStockInList(state, { listId, item, listName }) {
            let entries = state.lists[listName].find(list => list.id === listId).entries;
            const updatedEntries = entries.map(entry => entry.id === item.id ? Object.assign(entry, item) : entry);
            entries = updatedEntries;
        },

        deleteStockInList(state, { listId, entryId, listName }) {
            const list = state.lists[listName].find(list => list.id === listId);
            list.entries = list.entries.filter(entry => entry.id !== entryId);
        },

        focusOnList(state, { listIds }) {
            state.focusedLists = listIds;
        },

        unfocusOnList(state) {
            state.focusedLists = [];
        },

        reset(state) {
            Object.assign(state, initialState());
        },

        addPortfolioStock(state, { portfolioId, stock }) {
            const portfolio = state.lists.portfolios.find(p => p.id === portfolioId)
            portfolio.addStock(stock)
        },

        updatePortfolioStockLot(state, { portfolioId, stockId, lot }) {
            const portfolio = state.lists.portfolios.find(p => p.id === portfolioId)
            const stock = portfolio.stocks.find(s => s.stockId === stockId)
            stock.updateLot(lot)
        },

        deletePortfolioStockLot(state, { updatedPortfolio }) {
            const portfolio = state.lists.portfolios.find(p => p.id === updatedPortfolio.id)
            portfolio.stocks = updatedPortfolio.stocks
            //const stock = portfolio.stocks.find(s => s.stockId === stockId)
            //stock.removeLot(lotId)
        }
    },

    actions: {
        getAllBuyListWithEntries(context) {
            return new Promise((resolve, reject) => {
                getAllListWithEntries({ userId: this.state.user.authenticatedUser.userId, listType: 'buy' }).then((response) => {
                    context.commit('setUserLists', { listType: 'buyLists', lists: response.lists });
                    resolve();
                }).catch((error) => { reject(error) });
            });
        },

        getAllSellListWithEntries(context) {
            return new Promise((resolve, reject) => {
                getAllListWithEntries({ userId: this.state.user.authenticatedUser.userId, listType: 'sell' }).then((response) => {
                    context.commit('setUserLists', { listType: 'sellLists', lists: response.lists });
                    resolve();
                }).catch((error) => { reject(error) });
            });
        },

        getUserAllPortfolios(context) {
            return new Promise((resolve, reject) => {
                getUserAllPortfolios().then(lists => {
                    context.commit('setUserLists', { listType: 'portfolios', lists });
                    resolve();
                }).catch((error) => { reject(error) });
            })
        },

        addStockInWatchList(context, { listId, newEntry, listType }) {
            return new Promise((resolve, reject) => {
                addStockInUserWatchList({ listId: listId, newEntry, listType }).then((response) => {
                    const listName = getListNameFromType({ listType });
                    context.commit('addStockInList', { listId, item: response.addedUserListEntry, listName: listName });
                    this.dispatch('setUsage', { usage: response.usage }, { root: true });
                    resolve(response.addedUserListEntry);
                }).catch(error => { reject(error) })
            });
        },

        updateStockInUserWatchList(context, { listId, item, listType }) {
            return new Promise((resolve, reject) => {
                updateStockInUserWatchList({ listId, updatedListEntry: item.rawData, listType }).then((updatedUserListEntry) => {
                    const listName = getListNameFromType({ listType });
                    context.commit('updateStockInList', { listId, item: updatedUserListEntry, listName: listName });
                    resolve();
                }).catch(error => { reject(error) })
            });
        },

        deleteStockFromWatchList(context, { listId, entryId, listType }) {
            return new Promise((resolve, reject) => {
                deleteStockFromWatchList({ entryId, listId, listType }).then((response) => {
                    const listName = getListNameFromType({ listType });
                    context.commit('deleteStockInList', { listId, entryId, listName: listName });
                    this.dispatch('setUsage', { usage: response.usage }, { root: true });
                    resolve();
                }).catch(error => { reject(error) })
            });
        },

        createNewList(context, { userId, listName, listDescription, listType }) {
            return new Promise((resolve, reject) => {
                createNewList({ userId, listName, listDescription, listType }).then((response) => {
                    const listName = getListNameFromType({ listType });
                    context.commit('addList', { listName: listName, listInfos: response.list });
                    this.dispatch('setUsage', { usage: response.usage }, { root: true });
                    resolve();
                }).catch(error => { reject(error) })
            });
        },

        createNewPortfolio(context, { portfolioName, portfolioDescription, currency }) {
            return new Promise((resolve, reject) => {
                addPortfolio({ portfolioName, portfolioDescription, currency }).then((response) => {
                    context.commit('addList', { listName: 'portfolios', listInfos: response.portfolio })
                    this.dispatch('setUsage', { usage: response.usage }, { root: true })
                    resolve();
                }).catch(error => { reject(error) })
            });
        },

        updateListInfos(context, { listId, listName, listDescription, listType }) {
            return new Promise((resolve, reject) => {
                updateListInfo({ listId, listName, listDescription, listType }).then((response) => {
                    const listName = getListNameFromType({ listType });
                    context.commit('updatedList', { listInfos: response, listName: listName });
                    resolve()
                }).catch(error => { reject(error) })
            });
        },

        updatePortfolioInfos(context, { portfolioId, portfolioName, portfolioDescription, currency }) {
            return new Promise((resolve, reject) => {
                updatePortfolio({ portfolioId, portfolioName, portfolioDescription, currency }).then(() => {
                    const listInfos = {
                        id: portfolioId,
                        name: portfolioName,
                        description: portfolioDescription,
                        currency
                    }
                    context.commit('updatedList', { listInfos, listName: 'portfolios' });
                    resolve()
                }).catch(error => { reject(error) })
            })
        },

        deleteList(context, { listId, listType }) {
            return new Promise((resolve, reject) => {
                deleteList({ listId, listType }).then((response) => {
                    const listName = getListNameFromType({ listType });
                    context.commit('deleteList', { listId, listName: listName });
                    this.dispatch('setUsage', { usage: response.usage }, { root: true });
                    resolve();
                }).catch(error => { reject(error) })
            });
        },

        deletePortfolio(context, { portfolioId }) {
            return new Promise((resolve, reject) => {
                deletePortfolio({ portfolioId }).then(() => {
                    context.commit('deleteList', { listId: portfolioId, listName: 'portfolios' });
                    // TODO mettre à jour le usage
                    //this.dispatch('setUsage', { usage: response.usage }, { root: true });
                    resolve();
                }).catch(error => { reject(error) })
            })
        },

        hoverScoredStockCard(context, { stock }) {
            context.commit('focusOnList', { listIds: stock.getScoreInfos().map(e => e.list.id) });
        },

        leaveScoredStockCard(context) {
            context.commit('unfocusOnList');
        },

        resetLists(context) {
            context.commit('reset');
        },

        addPortfolioStock(context, { portfolioId, stockId }) {
            return new Promise((resolve, reject) => {
                addPortfolioStock({ portfolioId, stockId }).then(stock => {
                    resolve()
                }).catch(error => { reject(error) })
            })
        },

        addPortfolioStockLot(context, { portfolioId, stockId, transactionType, transactionDate, stockQty, averageCostByShare, note }) {
            return new Promise((resolve, reject) => {
                addPortfolioStockLot({ portfolioId, stockId, transactionType, transactionDate, stockQty, averageCostByShare, note }).then(updatedPortfolio => {
                    context.commit('replacePortfolio', { portfolio: updatedPortfolio })
                    resolve()
                }).catch(error => { reject(error) })
            })
        },

        updatePortfolioStockLot(context, { portfolioId, stockId, lotId, transactionType, transactionDate, stockQty, averageCostByShare, note }) {
            return new Promise((resolve, reject) => {
                updatePortfolioStockLot({ portfolioId, stockId, lotId, transactionType, transactionDate, stockQty, averageCostByShare, note }).then(updatedPortfolio => {
                    context.commit('replacePortfolio', { portfolio: updatedPortfolio })
                    resolve()
                }).catch(error => { reject(error) })
            })
        },

        deletePortfolioStockLot(context, { portfolioId, stockId, lotId }) {
            return new Promise((resolve, reject) => {
                deletePortfolioStockLot({ portfolioId, stockId, lotId }).then(updatedPortfolio => {
                    //context.commit('deletePortfolioStockLot', { updatedPortfolio })
                    context.commit('replacePortfolio', { portfolio: updatedPortfolio })
                    resolve(updatedPortfolio)
                }).catch(error => { reject(error) })
            })
        },

        importInPortfolio(context, { portfolioId, fileInput }) {
            return new Promise((resolve, reject) => {
                importInPortfolio({ portfolioId, fileInput }).then(importPortfolioResult => {
                    context.commit('replacePortfolio', { portfolio: importPortfolioResult.portfolio })
                    resolve(importPortfolioResult)
                }).catch(error => { reject(error) })
            })
        },

        movePortfolioStock(context, { fromPortfolioId, toPortfolioId, stockId }) {
            return new Promise((resolve, reject) => {
                movePortfolioStock({ fromPortfolioId, toPortfolioId, stockId }).then(updatedPortfolios => {
                    context.commit('replacePortfolio', { portfolio: updatedPortfolios.fromPortfolio })
                    context.commit('replacePortfolio', { portfolio: updatedPortfolios.toPortfolio })
                    resolve()
                }).catch(error => { reject(error) })
            })
        }
    },

    getters: {
        buyListsLoaded(state) {
            return state.loaded.buyLists;
        },
        sellListsLoaded(state) {
            return state.loaded.sellLists;
        },
        portfoliosLoaded(state) {
            return state.loaded.portfolios;
        },
        allListAreLoaded(state) {
            return state.loaded.sellLists & state.loaded.buyLists & state.loaded.portfolios
        },
        userLists(state) {
            return state.lists;
        },
        bestLists(state) {
            return state.bestLists
        },
        portfolioList(state) {
            return state.lists.portfolios
        },
        portfolioStock: (state) => ({ portfolioId, stockId }) => {
            const portfolio = state.lists.portfolios.find(p => p.id === portfolioId)
            return portfolio ? portfolio.stocks.find(s => s.stockId === stockId) : undefined
        },
        getListFromId: (state) => (listId) => {
            for (const listKey in state.lists) {
                for (const obj of state.lists[listKey]) {
                    if (obj.id === listId) {
                        return obj;
                    }
                }
            }
            return null;
        }
    }
}

export default listDataStore;
