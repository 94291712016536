import serverService from '../../services/ApiWrapper'
import storageService from '../../services/StorageService'
import { login, loginAsGuest, changeFullname } from '../../services/AuthenticationService'
import SubscriptionService from '../../services/SubscriptionService'
import AuthenticatedUserConverter from '../../infra/converters/AuthenticatedUserConverter'
import AuthenticatedUser from '../../models/AuthenticatedUser'
import DateHelper from '../../infra/DateHelper'
import PreferencesService from '../../services/PreferencesService'
import ThirdPartyAuthService from '../../services/ThirdPartyAuthService'

var userStore = {
    state: () => ({
        authenticatedUser: {},
        userSubscriptionInfo: {},
        userRoles: {},
        userAccess: {},
        allowCookies: false,
        invitationRequired: false,
        paymentMethods: {},
        plannedChangeOfPlan: {},
        preferences: {},
        authTokenExpirationTimer: {}
    }),

    mutations: {
        setAuthTokenExpirationTimer(state, timer) {
            if (state.authTokenExpirationTimer) {
                clearTimeout(state.authTokenExpirationTimer);
            }
            state.authTokenExpirationTimer = timer;
        },
        setUser(state, authUser) {
            if (!(authUser instanceof AuthenticatedUser)) throw new Error("authUser is not a AuthenticatedUser.");
            storageService.AuthUser = authUser.serialize();
            state.authenticatedUser = authUser;
            serverService.addToken({ token: authUser.token });
        },
        setUserSubscriptionInfo(state, userSubscriptionInfo) {
            state.userSubscriptionInfo = userSubscriptionInfo;
            state.plannedChangeOfPlan = userSubscriptionInfo.plannedChangeOfPlan;
        },
        setPlannedChangeOfPlan(state, plannedChangeOfPlan) {
            state.plannedChangeOfPlan = plannedChangeOfPlan;
        },
        setUserRoles(state, userRoles) {
            state.userRoles = userRoles;
        },
        setUserAccess(state, { userAccess }) {
            state.userAccess = userAccess;
            storageService.UserAccess = JSON.stringify(userAccess);
        },
        setUserFullname(state, { firstname, lastname }) {
            state.authenticatedUser.firstname = firstname;
            state.authenticatedUser.lastname = lastname;
            storageService.AuthUser = state.authenticatedUser.serialize();
        },
        setUsage(state, { usage }) {
            state.userSubscriptionInfo.usage = usage;
        },
        setAllowCookies(state, { allowCookies }) {
            state.allowCookies = !!allowCookies;
        },
        unsetUser(state) {
            state.authenticatedUser = {};
            state.userSubscriptionInfo = {};
            serverService.removeToken();
        },
        setUserSubscriptionTerminationInfo(state, terminationInfo) {
            state.userSubscriptionInfo.terminationInfo = terminationInfo;
        },
        setDefaultPaymentMethod(state, { defaultPaymentMethod }) {
            state.userSubscriptionInfo.defaultPaymentMethod = defaultPaymentMethod
        },
        updateDefaultPayementMethod(state, { defaultPaymentMethodId }) {
            state.paymentMethods.defaultPaymentMethodId = defaultPaymentMethodId
        },
        setInvitationRequired(state, { invitationRequired }) {
            state.invitationRequired = invitationRequired
        },
        setPaymentMethods(state, { paymentMethods }) {
            state.paymentMethods = paymentMethods
        }
    },

    actions: {
        setAuthTokenExpirationTimer(context, { timer }) {
            context.commit('setAuthTokenExpirationTimer', timer);
        },
        login(context, { email, password, rememberMe, token, googleToken }) {
            return new Promise((resolve, reject) => {
                var promise;
                if (googleToken) {
                    promise = ThirdPartyAuthService.google({ token: googleToken })
                }
                else {
                    promise = login({ email, password, token })
                }
                promise.then((response) => {
                    const authUser = AuthenticatedUserConverter.convert(response);
                    if (authUser.expiration) {
                        var delta = new Date(authUser.expiration) - (new Date());
                        var timer = setTimeout(() => {
                            context.dispatch('logoff');
                        }, delta);
                        context.dispatch('setAuthTokenExpirationTimer', { timer });
                    }
                    context.commit('setUser', authUser);
                    context.commit('setUserRoles', response.userRoles.roles);
                    const userAccess = rememberMe ? { user: email } : {};
                    context.commit('setUserAccess', { userAccess })
                    context.commit('setUserSubscriptionInfo', response.userSubscriptionInfo);
                    storageService.acceptedTermsVersion = response.lastAcceptedTermsAndConditionsVersion
                    resolve();
                }).catch(errors => { reject(errors); })
            });
        },

        loginAsGuest(context) {
            return new Promise((resolve, reject) => {
                loginAsGuest().then((response) => {
                    const authUser = AuthenticatedUserConverter.convert(response);
                    if (authUser.expiration) {
                        var delta = new Date(authUser.expiration) - (new Date());
                        var timer = setTimeout(() => {
                            context.dispatch('logoff');
                        }, delta);
                        context.dispatch('setAuthTokenExpirationTimer', { timer });
                    }
                    context.commit('setUser', authUser);
                    context.commit('setUserRoles', response.userRoles.roles);
                    resolve();
                }).catch(errors => { reject(errors); })
            });
        },

        logoff(context) {
            return new Promise((resolve, reject) => {
                context.commit('unsetUser');
                storageService.deleteAuthUser();
                resolve();
            });
        },

        getUserSubscriptionInfo(context) {
            return new Promise((resolve, reject) => {
                SubscriptionService.getUserSubscriptionInfo({}).then(response => {
                    context.commit('setUserSubscriptionInfo', response.subscription)
                    this.dispatch('setMyPlan', { planDefinition: response.subscription.planDefinition })
                    resolve(response)
                }).catch(errors => {
                    if (!Array.isArray(errors) || errors[0].errorCode !== "DemonstrationAccountNotAllowed") {
                        reject(errors)
                    }
                    resolve()
                })
            });
        },

        updateUserSubscriptionInfo(context, { userSubscriptionInfo }) {
            context.commit('setUserSubscriptionInfo', userSubscriptionInfo)
            context.commit('setPlannedChangeOfPlan', userSubscriptionInfo.plannedChangeOfPlan)
        },

        setPlannedChangeOfPlan(context, { plannedChangeOfPlan }) {
            context.commit('setPlannedChangeOfPlan', plannedChangeOfPlan)
        },

        setUserFullname(context, { firstname, lastname }) {
            return new Promise((resolve, reject) => {
                changeFullname({ firstname, lastname }).then((response) => {
                    context.commit('setUserFullname', { firstname, lastname });
                    resolve();
                }).catch((errors) => { reject(errors); });
            });
        },

        setUsage(context, { usage }) {
            context.commit('setUsage', { usage });
        },

        setAllowCookies(context, { allowCookies }) {
            context.commit('setAllowCookies', { allowCookies });
        },

        cancelSubscription(context) {
            return new Promise((resolve, reject) => {
                SubscriptionService.cancelSubscription().then(updatedSubscription => {
                    console.log("Subscription cancelled!!");
                    console.log(updatedSubscription.updatedSubscriptionInfo.terminationInfo);
                    context.commit('setUserSubscriptionTerminationInfo', updatedSubscription.updatedSubscriptionInfo.terminationInfo);
                    resolve(updatedSubscription.updatedSubscriptionInfo);
                }).catch((errors) => { reject(errors); });
            })
        },

        setDefaultPaymentMethod(context, { defaultPaymentMethod }) {
            context.commit('setDefaultPaymentMethod', { defaultPaymentMethod });
        },

        setInvitationRequired(context, { invitationRequired }) {
            context.commit('setInvitationRequired', { invitationRequired });
        },

        loadPaymentMethods(context) {
            return new Promise((resolve, reject) => {
                const preferencesService = new PreferencesService()
                preferencesService.getPaymentMethodsInfo().then(response => {
                    context.commit('setPaymentMethods', { paymentMethods: response })
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },

        updateDefaultPayementMethod(context, { defaultPaymentMethodId }) {
            context.commit('updateDefaultPayementMethod', { defaultPaymentMethodId });
        }
    },

    getters: {
        loggedIn(state) {
            return !!state.authenticatedUser.email;
        },

        user(state) {
            return state.authenticatedUser;
        },

        userSubscriptionInfo(state) {
            return state.userSubscriptionInfo;
        },

        canceledSubscription(state) {
            return state.userSubscriptionInfo.status === 'cancelled' && state.userSubscriptionInfo.terminationInfo.isCancel;
        },

        getDefaultPaymentMethod(state) {
            const id = state.paymentMethods.defaultPaymentMethodId
            return id ? state.paymentMethods.paymentMethods.methods.find(m => m.Id === id) : null
        },

        allowCookies(state) {
            return state.allowCookies;
        },

        hasRole(state) {
            return (role) => {
                if (role in state.userRoles) {
                    return state.userRoles[role].active;
                }
                else {
                    return false;
                }
            }
        },
        planHasPF(state) {
            return state.userSubscriptionInfo.planDefinition ? state.userSubscriptionInfo.planDefinition.limit.portfolioListCount > 0 : false
        },
        planDefinition(state) {
            return state.userSubscriptionInfo.planDefinition
        },
        planName(state) {
            return state.userSubscriptionInfo.planDefinition ? state.userSubscriptionInfo.planDefinition.planName : 'null'
        },
        planUsage(state) {
            return state.userSubscriptionInfo.usage
        },
        terminationInfo(state) {
            return state.userSubscriptionInfo.terminationInfo;
        },
        isInDefaultOfPayment(state) {
            return !!state.userSubscriptionInfo.defaultOfPayment
        },
        isInvitationRequired(state) {
            return state.invitationRequired
        },
        getPaymentMethods(state) {
            return state.paymentMethods
        },
        plannedChangeOfPlan(state) {
            return state.plannedChangeOfPlan
        },
        messages(state) {
            const msgs = []
            const dop = state.userSubscriptionInfo.defaultOfPayment
            let code, subcode, dismissedMsg
            let type = 'info'
            const dismissedMessages = storageService.dismissAppMessage
            if (dop) {
                code = dop.reason;
                dismissedMsg = !!dismissedMessages[code]
                subcode = ''
                type = 'warning'
                const ti = state.userSubscriptionInfo.terminationInfo
                if (ti.endOfGracePeriodDate) {
                    if (Date.parse(ti.endOfGracePeriodDate) > Date.parse(DateHelper.serverDateTimeSnapshot)) {
                        subcode = 'gracePeriodEndDate'
                    }
                }
                msgs.push({ code, subcode, type, dismissedMsg })
            }
            return msgs
        }
    }
}

export default userStore;
