<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text :small="mobile" color="agora">
                <font-awesome-icon :icon="icons.add" class="fa-fw fa-lg mr-2"></font-awesome-icon> {{ $t('UserStockWatchingList.portfolio.addALot') }}
            </v-btn>
        </template>
        <div class="addLotForm">
            <div class="addLotFormHeader">
                <div class="symbol">{{ symbol }}</div>
                <div class="alfhTitle">{{ dialogTitle }}</div>
            </div>
            <div class="addLotFormBody">
                <v-form ref="addForm" v-model="valid">
                    <div class="newLot">
                        <div class="newLotType">
                            <v-btn-toggle v-model="lotType" mandatory color="agora">
                                <v-btn>
                                    <font-awesome-icon :icon="getListIconFromType('buy')" class="fa-fw fa-lg mr-2"></font-awesome-icon>{{ $t('UserStockWatchingList.buttons.buy') }}
                                </v-btn>
                                <v-btn>
                                    <font-awesome-icon :icon="getListIconFromType('sell')" class="fa-fw fa-lg mr-2"></font-awesome-icon>{{ $t('UserStockWatchingList.buttons.sell') }}
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                        <div class="newLotDQP">
                            <div>
                                <v-menu
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="date"
                                            :label="$t('UserStockWatchingList.portfolio.date')"
                                            append-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                            @keydown.tab="setFocus('quantityField')"
                                            @click:append="menu=true"
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" @input="menu=false" :max="maxDate"></v-date-picker>
                                </v-menu>
                            </div>
                            <div>
                                <v-text-field
                                    ref="quantityField"
                                    name="quantity"
                                    v-model="qty"
                                    :label="$tc('UserStockWatchingList.portfolio.quantity', 2)"
                                    required
                                    dense
                                    outlined
                                    type="number"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                            <div>
                                <v-text-field
                                    name="costByShare"
                                    v-model="costByShare"
                                    outlined
                                    dense
                                    type="number"
                                    :label="$tc('UserStockWatchingList.portfolio.priceByShare', 2)"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </div>
                        <div class="newLotNote">
                            <v-text-field
                                name="note"
                                v-model="note"
                                outlined
                                dense
                                hide-details="auto"
                                :label="$t('UserStockWatchingList.portfolio.note')"
                            ></v-text-field>
                        </div>
                    </div>
                </v-form>
            </div>
            <div class="newLotFooter">
                <div v-if="id" class="footPart">
                    <v-btn fab small @click="deleteLotAsked" color="error">
                        <font-awesome-icon :icon="icons.delete" class="fa-fw fa-lg"></font-awesome-icon>
                    </v-btn>
                </div>
                <div class="footPart right">
                    <v-btn fab small @click="confirm" color="primary" :disabled="confirmAddLotDisabled">
                        <font-awesome-icon :icon="icons.ok" class="fa-fw fa-lg"></font-awesome-icon>
                    </v-btn>
                    <v-btn fab small dark @click="cancel">
                        <font-awesome-icon :icon="icons.cancel" class="fa-fw fa-lg"></font-awesome-icon>
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { faArrowRight, faCheck, faPlus, faTimes, faTrashAlt } from '@fortawesome/pro-light-svg-icons'
import DateHelper from '../../infra/DateHelper'
import StockHtmlHelper from '../stocks/StockHtmlHelper';
import ListLightIconMixin from '../help/icons/ListLightIconMixin';
export default {
    props: {
        stock: { type: Object },
        mobile: { type: Boolean, default: false }
    },
    mixins: [ListLightIconMixin],
    data() {
        return {
            icons: {
                add: faPlus,
                ok: faCheck,
                cancel: faTimes,
                delete: faTrashAlt,
                move: faArrowRight
            },
            dialog: false,
            valid: false,
            menu: false,
            id: undefined,
            lotType: 0,
            date: undefined,
            qty: undefined,
            costByShare: undefined,
            note: undefined,
            stockHtmlHelper: undefined,
            rules: {
                required: value => !!value || this.$t("rules.required")
            }
        };
    },
    created() {
        this.stockHtmlHelper = new StockHtmlHelper();
    },
    computed: {
        dialogTitle() {
            return this.$t(`UserStockWatchingList.portfolio.${this.id ? "editLot" : "addALot"}`);
        },
        confirmAddLotDisabled() {
            return !this.valid;
        },
        maxDate() {
            return DateHelper.today();
        },
        symbol() {
            return this.stock ? this.stock.symbol : "";
        }
    },
    methods: {
        show({ transitionType, lotId, quantity, averageTransactionPrice, dateOfTransaction, note }) {
            this.id = lotId;
            this.lotType = transitionType === "buy" ? 0 : 1;
            this.date = DateHelper.toSimpleDate(dateOfTransaction);
            this.qty = quantity;
            this.costByShare = this.stockHtmlHelper.formatPriceWithDigit(averageTransactionPrice, 2);
            this.note = note;
            this.dialog = true;
        },
        reset() {
            this.id = undefined;
            this.date = undefined;
            this.qty = undefined;
            this.costByShare = undefined;
            this.note = undefined;
            if (this.$refs.addForm) {
                this.$refs.addForm.resetValidation();
            }
        },
        confirm() {
            const data = {
                lotId: this.id,
                transactionDate: DateHelper.dateTimeToLocaleJSON({ strDate: this.date }),
                stockQty: parseInt(this.qty),
                averageCostByShare: parseFloat(this.costByShare),
                note: this.note,
                transactionType: this.lotType === 0 ? "Buy" : "Sell"
            };
            this.$emit("saveLot", data);
            this.reset();
            this.dialog = false;
        },
        cancel() {
            this.$emit("cancel");
            this.reset();
            this.dialog = false;
        },
        deleteLotAsked() {
            this.$emit("delete", { lotId: this.id })
        },
        setFocus(refComp) {
            this.$refs[refComp].focus()
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/breakpoints.scss";
.addLotForm {
    background-color: #FFF;
    >* {
        padding: 15px;
    }
    .addLotFormHeader {
        .alfhTitle {
            font-size: 1.1rem;
        }
        .symbol {
            font-size: 1.4rem;
            font-weight: bold;
        }
    }
    .addLotFormBody {
        .newLot {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .newLotType {
                margin-bottom: 16px;
            }
            .newLotDQP {
                display: flex;
                align-items: center;
                gap: 15px;
            }
        }
    }
    .newLotFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footPart {
            flex: 1;
            display: flex;
            gap: 10px;
            &.right {
                justify-content: flex-end;
            }
        }
    }
    @media screen and (max-width: $tablet) {
        .addLotFormBody {
            .newLot {
                .newLotDQP {
                    flex-direction: column;
                    align-items: stretch;
                    ::v-deep .v-text-field {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>