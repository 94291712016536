<template>
    <div>
        <v-menu
            offset-y
            v-if="loggedIn"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-avatar color="#2d353c" size="40">
                        <img :src="userAvatar" :alt="nickname" v-if="userAvatar" />
                        <span class="white--text" v-else>{{nickname}}</span>
                    </v-avatar>
                </v-btn>
            </template>
            <v-card
                class="mx-auto"
                width="350"
                tile
            >
                <v-list>
                    <div class="userInfos">
                        <div class="ui-name">{{user.fullname()}}</div>
                        <div class="ui-email">
                            <span>{{user.email}}</span>
                            <img src="~@/assets/google-button-tr.png" alt="Google" v-if="googleAccount" />
                        </div>
                    </div>
                    <v-list-item
                        link
                        @click="goToMyAccount"
                    >
                        <v-list-item-icon>
                            <font-awesome-icon :icon="icons.myAccount" class="fa-fw fa-lg"></font-awesome-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{$t('avatar.myAccount')}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <div v-if="isNotDemo">
                    <v-divider></v-divider>
                    <UsagePanel @editSubscriptionAsked="editSubscriptionAsked"></UsagePanel>
                </div>
                <v-divider></v-divider>
                <v-list>
                    <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item
                        v-for="(item, i) in mainNavigationListItem"
                        :key="i"
                        @click="go(item.route)"
                        >
                            <v-list-item-icon>
                                <font-awesome-icon :icon="icons[item.icon]" class="fa-fw fa-lg"></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{$t(item.text)}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="logoff">
                            <v-list-item-icon>
                                <font-awesome-icon :icon="icons.logoff" class="fa-fw fa-lg"></font-awesome-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{$t('avatar.logoff')}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-menu>
        <v-btn v-else dark icon>
            <v-icon>mdi-account-circle-outline</v-icon>
        </v-btn>
    </div>
</template>

<script>
import UsagePanel from '../plan/UsagePanel'
import { authComputed } from "@/store/helpers/AuthenticationHelper"
import { authorizationComputed } from "@/store/helpers/AuthorizationHelper"
import { faPowerOff, faTachometerAltFast, faUserShield, faUserCog, faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
export default {
    data () {
        return {
            hoverUser: false,
            icons: {
                myAccount: faUserCog,
                dashboard: faTachometerAltFast,
                admin: faUserShield,
                help: faInfoCircle,
                logoff: faPowerOff
            },
            selectedItem: undefined
        }
    },
    components: {
        UsagePanel
    },
    methods: {
        go(routeName) {
            this.$router.push({ name: routeName }).catch(() => {});
        },
        logoff() {
            this.$store.dispatch('logoff').then(() => {
                this.$store.dispatch('resetLists').then(() => {
                    this.$router.push({ name: 'Home' }).catch(() => { });
                });
            });
        },
        goToMyAccount() {
            this.$router.push({ name: 'MyAccount' });
        },
        editSubscriptionAsked() {
            this.$emit('editSubscriptionAsked');
        },
        getListItemClass(route) {
            return route === this.$route.name ? 'highlighted' : '';
        },
        showHelp() {
            this.$emit('helpAsked');
        }
    },
    computed: {
        ...authComputed,
        ...authorizationComputed,
        userAvatar() {
            return this.user.imageUrl// || this.user.nickname;
        },
        nickname() {
            return this.user.nickname;
        },
        googleAccount() {
            return this.user && this.user.imageUrl;
        },
        mainNavigationListItem() {
            const items = [
                { text: 'avatar.dashboard', icon: 'dashboard', route: 'Home' }
            ];
            if (this.hasRole('admin')) {
                items.push({ text: 'avatar.admin', icon: 'admin', route: 'Admin' });
            }
            return items;
        },
        isNotDemo() {
            return !this.$store.getters.hasRole('demonstration')
        }
    },
	watch: {
		$route(to, from) {
			const items = this.mainNavigationListItem;
			this.selectedItem = items.findIndex(i => i.route === to.name);
		}
	}
};
</script>

<style lang="scss" scoped>
.userInfos {
    background-color: var(--darkBG);
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .ui-name {
        font-size: large;
        font-weight: bold;
    }
    .ui-email {
        color: var(--lightBG);
        display: flex;
        align-items: center;
        gap: 10px;
    }
}
</style>
